.total {
  position: relative;
  color: #fff;
  &__title {
    background: #143E62;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding-left: 0.8rem;
  }
  &__td {
    width: 25%;
    text-align: center;
    &_type {
      &_left {
        text-align: left;
      }
    }
  }
  &__input-block {
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 1em 1em;
  }
  &__button {
    width: 100%;
    height: 2.125rem;
    background: #337AB7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    &_isLoading {
      background: #337AB7;
      cursor: not-allowed;
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    background: #143E62;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 400;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 1.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 0.75rem;
    &_type {
      &_bold {
        font-weight: bold;
      }
    }
  }
}
