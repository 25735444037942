.locale {
  color: #fff;
  &__title {
    background: #143E62;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
  &__item {
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    &_active {
      background: #313131;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    width: 100%;
    height: 2.125rem;
    background: #337AB7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}
