.user-edit {
  color: #fff;
  &__info {
    padding: 1rem 1rem 0 1rem;
    &-item {
      margin-bottom: 0.8rem;
    }
    &-title {
      margin-bottom: 0.3rem;
    }
  }

  &__value {
    width: 100%;
    min-height: 2.2em;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.5rem;
    border: 1px solid #ddd;
    font-size: 1rem;
  }

  &__buttons {
    padding: 0 1rem 1rem;
  }

  &__buttons-bottom {
    display: flex;
    justify-content: space-between;
  }

  &__ban-button,
  &__unban-button {
    cursor: pointer;
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 2.5rem;
  }

  &__unban-button {
    background: #157F5D;
  }

  &__ban-button {
    background: #DF0F02;
  }

  &__reset-button,
  &__payment-button {
    cursor: pointer;
    background: #337AB7;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 1rem;
  }

  &__payment-block {
    padding: 0 1rem;
    box-sizing: border-box;
  }

  &__payment-button {
    margin: 1.25rem auto 1rem auto;
  }

  &__payment-item {
    margin-bottom: 0.5rem;
  }

  &__payment-title {
    margin-bottom: 0.3rem;
  }
}
