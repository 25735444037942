.menu-item {
  width: 100%;
  height: 2.8rem;
  margin-bottom: 2px;
  color: #fff;
  background-color: #1F1F1F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  > * {
    fill: #fff;
  }

  &__image {
    display: block;
    width: 1.5rem;
  }
  &__element-list {
    width: 100%;
    box-sizing: border-box;
    padding: .5rem 1rem;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    box-shadow: 0 1px 3px rgba(0,0,0,.15);
    width: 100%;
    height: 2.5rem;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    margin-bottom: 5px;
    &:last-child {
      border-bottom: 1px solid #fff;
    }
  }
}
