.change-pas {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  &__text {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  &__button {
    margin-top: 1rem;
    width: 100%;
    height: 2rem;
    background: #337AB7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    border: none;
  }
}
