.transaction-item {
  font-size: 0.7857rem;
  width: 100%;
  box-sizing: border-box;
  height: 1.6429rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #404040;
  background: #181c23;

  &_left {
    align-items: flex-start;
    padding-left: 1rem;
  }

  &_isLow {
    color: #c22326;
  }

  &_isHigh {
    color: #00890d;
  }

  &_right {
    padding-right: 1rem;
  }
}
