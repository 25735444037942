.input {
  width: 100%;
  min-height: 2.25rem;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem;
  border: 1px solid #ddd;
  color: #fff;
  background-color: transparent;
  font-size: 1rem;

  &_type {
    &_checkbox {
      width: 1.5rem;
    }
  }
}
